<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="needAudit">
          <el-select
              v-model="query.needAudit"
              clearable
              placeholder="请选择车场状态"
              class="width300"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="待处理" value="1"></el-option>
            <el-option label="已处理" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="regionId">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>

<!--        <el-form-item prop="settlementId">-->
<!--          <Settlement v-model="query.settlementId" style="width: 300px"/>-->
<!--        </el-form-item>-->

        <el-form-item prop="parklotId">
          <SuggestParking v-model="query.parklotId" class="width300"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">车场数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
      </div>
    </div>
    <div class="content-box section">
      <div v-loading="isLoading">
        <el-row :gutter="20">
          <el-col v-for="item in dataList" :key="item.parkingLotId" :span="6">
            <div class="parkingLot-card" @click="goDetail(item)">
              <div class="check-badge">
                <span class="badge-value text-hide">{{ item.manualAuditNumber }}</span>
              </div>
              <div class="info-card">
                <div class="parklot-name text-hide">
                  <div></div>
                  <div>{{ item.parklotName }}</div>
                </div>
                <div class="parklot-region text-hide">{{ item.provinceName }}-{{ item.cityName }}-{{ item.areaName }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {getRegionList} from "@/api/user";
import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import BaseMixin from "@/mixins/base";
import {MessageBox} from "element-ui";
import {checkToken} from "@/api/common";
// import {getParklotList,} from "@/api/manualReview";

export default {
  mixins: [BaseMixin],
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList2222:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      isLoading: false,
      query: {
        needAudit: "1", //车场状态  0全部  1待处理  2已处理
        regionId: "530129", //区域
        settlementId: "", //结算方id
        parklotId: "", //停车场id
      },
      dataList: [],
    };
  },
  components: {
    SuggestParking,
    // Settlement,
  },
  methods: {
    //跳转车场详情
    goDetail(item) {
      this.$router.push({
        path: "/operation_manage/manual_review/parkinglot_detail",
        query: {
          parkingLotId: item.parkingLotId,
          parklotName: item.parklotName,
          provinceName: item.provinceName,
          cityName: item.cityName,
          areaName: item.areaName,
        },
      });
    },
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageSize = 10;
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      checkToken()
          .then(res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              // this.isLoading = true;
              // this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
              // const params = this.paramFormat(this.query);
              // const res = await getParklotList(params);
              // this.isLoading = false;
              // if (res && res.code === 30 && res.result) {
              //   const returnObject = res.returnObject;
              //   this.dataList = returnObject.list;
              //   this.pagination.totalSize = returnObject.count;
              //   this.pagination.totalPages = returnObject.totalPage;
              //   // this.form.exportSize = returnObject.total;
              // }
            }
          })
    },
  }
};
</script>

<style lang="less" scoped>
.parkingLot-card {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 94%;
  border: 1px solid #dae0f1;
  border-radius: 4px;
  border-left: 1px solid #4c70e8;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: .3s;

  .check-badge {
    width: 80px;
    height: 40px;
    border-radius: 50%;
    background-color: #ff6759;
    color: #fff;
    text-align: center;
    position: absolute;
    right: -26px;
    top: -18px;

    .badge-value {
      position: absolute;
      right: 20px;
      top: 12px;
      width: 60px;
    }
  }

  .info-card {
    padding: 20px;
  }

  .parklot-region {
    color: #686e77;
  }

  .text-hide {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 32px;
    padding-left: 20px;
  }
}

.parkingLot-card:hover {
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>
